@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import '~@fsi/fsi-styles/dist/style.css';

html,
body {
    margin: 0px;
    height: 100%;
    background-color: var(--fsi-color-white);
    font-family: 'Manrope';
}

p{
  font-family: 'Open Sans';
}

a { color: inherit; text-decoration: inherit; } 

.homepage-layout--hero {
  flex:1; 
}
@media (min-width: 768px) {
  .homepage-layout--hero {
    background: url('./assets/architecture.png') no-repeat; 
    background-size:cover; 
    background-position: right;
  }
}

table {
  width: 100%;
  
  td,th {
      word-wrap: break-word;
      max-width: 300px;
      margin: 0px;
      padding: .5rem;
      flex-grow: 1;
  }
}

.container {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
  }

  .container.padded {
    padding: 15px;
  }
  
  .row::after {
    content: "";
    clear: both;
    display: table;
  }
  
  .pull-right {
    float: right;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .no-padding {
    padding: 0px;
  }

  .side-padding {
    padding-left: 15px;
    padding-right: 15px;
  }

.box-container {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box-container .header {
  flex: 0 1 auto;
}

.box-container .content {
  flex: 1 1 auto;
  overflow-y: auto;
}

.box-container .footer {
  flex: 0 1 50px;
}

.container-fluid {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.container-grid {
  width: 100%;
  overflow-x: auto;
  tbody {
      tr {
          cursor: pointer;
          opacity: 0.8;

          &:hover {
              opacity: 1;
          }
      }
  }
}

@media (min-width: 768px) {
  .container-fluid {
    padding: 0px;
  }
}

@media (min-width: 992px) {
  .container-fluid {
    padding: 30px;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    padding: 45px;
  }
}

 $class-col: col !default;

@for $i from 1 through 12 {
    .#{$class-col}-#{$i},
    .#{$class-col}-sm-#{$i},
    .#{$class-col}-md-#{$i},
    .#{$class-col}-lg-#{$i} {
      width: calc($i / 12) * 100%;
      float: left;
    }
    
    .#{$class-col}-offset-#{$i},
    .#{$class-col}-sm-offset-#{$i},
    .#{$class-col}-md-offset-#{$i},
    .#{$class-col}-lg-offset-#{$i} {
      margin-left: calc($i / 12) * 100%;
    }

    @media (max-width: 768px) {
      .#{$class-col}-sm-#{$i} {
          width: 100%;
          float: left;
      }
    
      .#{$class-col}-sm-offset-#{$i} {
          margin-left: 0;
      }
    }

    @media (max-width: 992px) {
      .#{$class-col}-md-#{$i} {
          width: 100%;
          float: left;
      }
    
      .#{$class-col}-md-offset-#{$i} {
        margin-left: 0;
      }
    }

    @media (max-width: 1200px) {
      .#{$class-col}-lg-#{$i} {
          width: 100%;
          float: left;
      }
    
      .#{$class-col}-lg-offset-#{$i} {
          margin-left: 0;
      }
    }
}


.btn {
  text-align: left;
  background-color: inherit;
  box-shadow: none;
  border: none;
  cursor: pointer;
  transition: background-color .5s;
  height: 50px;
  line-height: 25px !important;
  vertical-align: middle !important;
}

.btn:hover {
  background-color: rgba(0,0,0,0.25);
}

.btn-rise {
    text-align: left;
    background-color: inherit;
    box-shadow: none;
    border: none;
    cursor: pointer;
    transition: transform .2s, background-color .2s, box-shadow .2s;
    transform: scale(1, 1);
}

.btn-rise.square {
    width: 120px;
    height: 120px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn-square-label{
    text-align: center;
    font-size: 16px;
}

.flex-row {
  display: flex; 
  flex-flow: row wrap; 
  justify-content: space-between;
}

.notification-area{
  background-color: white; 
  width: fit-content; 
  height: fit-content;
  margin: 0 auto; 
  padding: 15px; 
  max-width: 400px; 
  box-shadow: 0 2px 6px rgba(0,0,0,0.2); 
}

.grayscale {
  filter: grayscale(.875)
}
